import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ useSecondaryColor?: boolean }>`
  display: inline-block;
  padding: 2px 6px 3px;
  background-color: ${(props) => (props.useSecondaryColor ? props.theme.secondary : props.theme.primary)};
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const BadgeText = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.white};
  font-size: 11px;
`;

export const Placeholder = styled.div<{ $useWideDisplay?: boolean }>`
  ${({ $useWideDisplay }) =>
    $useWideDisplay
      ? css`
          @media (${mobileXXLargeMax}) {
            padding-bottom: 30px;
          }
        `
      : css`
          padding-bottom: 30px;
        `}
`;
