import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled, { css } from 'styled-components';

interface UseWideDisplay {
  $useWideDisplay?: boolean;
}

interface Loading {
  $isLoading?: boolean;
}

export const Container = styled.li<UseWideDisplay & Loading>`
  position: relative;
  display: flex;
  flex-direction: column;

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.white};
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.white};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.primary};
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 32px;
  }
`;

export const LinkContainer = styled(Link)<Loading & UseWideDisplay & { $borderColor?: string }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme, $isLoading }) => ($isLoading ? 'transparent' : theme.background)};
  flex: 1;
  overflow: hidden;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);

  &:visited h2 {
    color: ${({ theme }) => theme.backgroundMid};
  }

  &:hover {
    background-color: ${({ $isLoading, theme }) => ($isLoading ? 'transparent' : theme.backgroundLighter)};
  }

  ${({ $borderColor, $useWideDisplay }) => {
    if ($useWideDisplay && $borderColor) {
      return css`
        border-radius: 5px 5px 10px 10px;
        border: 2px solid ${$borderColor};
        border-top: 8px solid ${$borderColor};

        @media (${mobileXXLargeMax}) {
          border-radius: 10px;
          border-top: 2px solid ${$borderColor};
          border-bottom: 8px solid ${$borderColor};
        }
      `;
    }

    if ($borderColor) {
      return css`
        border-radius: 10px;
        border: 2px solid ${$borderColor};
        border-bottom: 8px solid ${$borderColor};
      `;
    }

    return css`
      border-radius: 0 0 10px 10px;
    `;
  }}

  @media (${mobileXXLargeMax}) {
    // Fixes box height on iOS 10
    height: auto;
  }
`;

export const Content = styled.div<UseWideDisplay>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      flex-direction: row;

      @media (${mobileXXLargeMax}) {
        flex-direction: column;
      }
    `}
`;

export const ImageContent = styled.div<UseWideDisplay>`
  position: relative;
  overflow: hidden;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      flex-basis: 44%;
      height: 100%;

      @media (${mobileXXLargeMax}) {
        flex-basis: initial;
        width: 100%;
        height: auto;
        min-width: 0px;
        max-width: none;
      }
    `}
`;

export const ViewsPill = styled.p`
  padding: 4px 10px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  color: ${({ theme }) => theme.textDark};
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  font-size: 14px;
`;

export const TextBox = styled.div<UseWideDisplay>`
  position: relative;
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      min-height: 100%;

      @media (${mobileXXLargeMax}) {
        min-height: initial;
      }
    `}
`;

export const TextBoxTop = styled.div<UseWideDisplay & { $isDevelopment: boolean }>`
  padding-right: ${({ $isDevelopment }) => ($isDevelopment ? '0' : '96px')};

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      @media (${mobileXXLargeMax}) {
        padding-right: 0;
      }
    `}
`;

export const TextBoxBottom = styled.div<UseWideDisplay>`
  padding-right: 96px;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      padding-right: 0;

      @media (${mobileXXLargeMax}) {
        padding-right: 96px;
      }
    `}
`;

export const AccountLogos = styled.div<UseWideDisplay & { $enhancedBranding: boolean }>`
  position: absolute;

  img {
    max-width: 96px;
  }

  ${({ $useWideDisplay, $enhancedBranding }) => {
    if ($useWideDisplay) {
      return css`
        right: ${$enhancedBranding ? '0' : '15px'};
        top: ${$enhancedBranding ? '0' : '15px'};

        @media (${mobileXXLargeMax}) {
          bottom: ${$enhancedBranding ? '0' : '15px'};
          top: initial;
        }
      `;
    }

    return css`
      right: ${$enhancedBranding ? '0' : '15px'};
      bottom: ${$enhancedBranding ? '0' : '15px'};
    `;
  }}
`;

export const DeveloperLogos = styled.div<UseWideDisplay>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.backgroundDark};
    margin: 0 0 5px;
    text-align: center;
  }

  img {
    max-height: 40px;
    max-width: none;
  }

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      flex-direction: column;

      @media (${mobileXXLargeMax}) {
        flex-direction: row-reverse;
      }
    `}
`;

export const SellingAgentLogos = styled.div<UseWideDisplay>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  img {
    max-height: 18px;
    max-width: none;
  }

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      flex-direction: column;

      @media (${mobileXXLargeMax}) {
        flex-direction: row;
      }
    `}
`;

export const AccountLogo = styled.div<{ $isEnhanced?: boolean }>`
  width: 96px;
  height: 96px;
  background-size: 79px, auto, contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ContactText = styled.p`
  display: inline-block;
  background-color: ${({ theme }) => theme.backgroundLighter};
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 10px;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  button {
    display: flex;
    font-size: 16px;
    color: ${({ theme }) => theme.textDark};
    margin-right: 10px;

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const DevelopmentBoxFiller = styled.div<UseWideDisplay>`
  padding-top: 60px;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      padding-top: 0;

      @media (${mobileXXLargeMax}) {
        padding-top: 60px;
      }
    `}
`;

export const OptionsRow = styled.div<UseWideDisplay>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  right: 15px;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      bottom: 8px;
      top: initial;

      @media (${mobileXXLargeMax}) {
        top: 8px;
        bottom: initial;
      }
    `}
`;

export const HeroNoImage = styled.img`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLighter};
`;

export const HiddenAddress = styled.span`
  color: ${({ theme }) => theme.backgroundLight};
  font-weight: bold;
`;

export const HiddenContainer = styled.div<UseWideDisplay>`
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ $useWideDisplay }) =>
    $useWideDisplay
      ? css`
          flex-direction: row;
          justify-content: space-between;

          ${HiddenAddress} {
            flex: 1 1 auto;
          }
        `
      : css`
          height: 100%;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        `}
`;
