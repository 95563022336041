import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';
import { Container as OldPropertyBox } from '../property-box/OldPropertyBox.style';
import { Container as PropertyBox } from '../property-box/PropertyBox.style';
import { Container as PropertyPlaceholder } from './PropertyPlaceholder.style';

export const Container = styled.div<{ background?: string }>`
  padding: 65px 0 25px;
  background-color: ${(props) => props.background || props.theme.background};

  @media (${laptopMediumMax}) {
    padding: 30px 0;
    margin: 0;
  }

  @media print {
    display: none;
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  padding: 0 30px;

  @media (${laptopMediumMax}) {
    padding: 0 15px;
  }
`;

export const Title = styled.h2<{ $fontSize?: number }>`
  font-size: ${({ $fontSize }) => $fontSize || 22}px;
  color: ${(props) => props.theme.textDark};
  margin-left: 30px;

  @media (${laptopMediumMax}) {
    font-size: 26px;
    margin-left: 15px;
  }
`;

export const EmptyText = styled.p`
  color: ${(props) => props.theme.textDark};
  margin-top: 15px;
  margin-left: 30px;

  @media (${laptopMediumMax}) {
    margin-left: 15px;
  }
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.textLight};
  font-weight: normal;
  margin-left: 30px;

  @media (${laptopMediumMax}) {
    display: block;
    margin-left: 15px;
  }
`;

export const Content = styled.div<{ $wrapOnDesktop?: boolean; $cols?: number }>`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;
  
  .swiper {
    margin: 30px 15px;
  }
  
  .swiper-wrapper {
    flex-wrap: ${({ $wrapOnDesktop }) => ($wrapOnDesktop ? 'wrap' : 'nowrap')};
  }
  
  .swiper-slide {
    display: flex;
    width: calc(100% / ${({ $cols }) => $cols || 3});
    min-width: 320px;
    height: auto;
    padding: 0 15px 15px;

    .pp-property-box {
      width: 100%;
    }
  }
}

  @media (${laptopMediumMax}) {
    .swiper {
      margin: 15px 0 0;
      padding: 0 7.5px 15px;
    }

    .swiper-wrapper {
      flex-wrap: nowrap;
    }
    
    .swiper-slide {
      padding: 0 7.5px 15px;
    }
`;

export const Rail = styled.ul<{ $fullWidth?: boolean; $cols?: number }>`
  display: flex;
  margin: 30px 0;
  padding: 0 30px;
  flex-wrap: ${({ $fullWidth }) => ($fullWidth ? 'nowrap' : 'wrap')};
  overflow-x: ${({ $fullWidth }) => ($fullWidth ? 'scroll' : 'visible')};
  gap: 30px;

  ${PropertyBox}, ${PropertyPlaceholder}, ${OldPropertyBox} {
    width: calc((100% - 120px) / ${({ $cols }) => $cols || 3});
    min-width: ${({ $fullWidth }) => ($fullWidth ? '380px' : '275px')};
    margin-bottom: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (${laptopMediumMax}) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    gap: 15px;
  }
`;

export const SliderButton = styled.button<{ $direction: string; $arrowBg?: string }>`
  background-color: ${({ theme, $arrowBg }) => $arrowBg || theme.backgroundLightest};
  position: absolute;
  top: 0;
  bottom: 60px;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${({ $direction }) => ($direction === 'left' ? 'left: -50px;' : 'right: -50px;')}

  svg {
    color: ${({ theme }) => theme.textDark};

    ${({ $direction }) => ($direction === 'left' ? 'margin-right: 4px;' : 'margin-left: 4px;')}
  }

  &:hover {
    background-color: ${({ theme }) => theme.backgroundLighter};
  }

  @media (max-width: 1610px) {
    ${({ $direction }) => ($direction === 'left' ? 'left: 40px;' : 'right: 40px;')}
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  @media (${laptopMediumMax}) {
    display: none;
  }
`;
